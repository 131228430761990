import React from 'react';
import styled from '@emotion/styled';
import Image from 'gatsby-image';
import { Link } from "gatsby";
import urlSlug from 'url-slug';

const Card = styled.div`
    border: 1px solid #e1e1e1;
    img{
        width: 100%;
        height: auto;
        object-fit: fill;
    }
    
`;


const TarjetaPreview = ({tarjeta}) => {
    const { codigo, imagen } = tarjeta;
    //console.log(imagen.sharp.fluid);
    return (  
        <Card>
            <Link to={`/${urlSlug(codigo)}`}>
                <Image 
                    fluid={imagen.sharp.fluid}
                />
            </Link>
            
        </Card>
        
    );
}
 
export default TarjetaPreview;