import React, {useState} from 'react';
import { graphql, useStaticQuery } from "gatsby";
import styled from '@emotion/styled';

const Formulario = styled.form`
    width: 100%;
    display: flex;
    border: 1px solid #e1e1e1;
    max-width: 1200px;
    margin: 2rem auto 0 auto;
`;
const Select = styled.select`
    flex: 1;
    padding: 1rem;
    appearance: none;
    border: none;
    font-family: 'Lato', sans-serif;
`;

const useFiltroTema = () => {

    const [tema, setTema] = useState('');

    const resultado = useStaticQuery(graphql`
        query {
            allStrapiTemas {
                nodes {
                    id
                    nombre
                }
            }
        }      
    `);
    
    console.log(resultado);
    const temas = resultado.allStrapiTemas.nodes;
    console.log(temas);
    const FiltroUI = () => (
        <Formulario>
            <Select
                onChange={ e => setTema( e.target.value)}
                value={tema}
            >
                <option value="">Seleccionelos por Tema</option>
                {temas.map(opcion => (
                    <option 
                        key={opcion.id} 
                        value={opcion.nombre}
                    >{opcion.nombre}</option>
                ))}
            </Select>
        </Formulario>
    )
    
    console.log(tema);
    return {
        tema,
        FiltroUI
    }

}

export default useFiltroTema;