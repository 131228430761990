import { graphql, useStaticQuery } from "gatsby";

const useArticulos = () => {
    const datos = useStaticQuery(graphql`
        query{
            allStrapiArticulos {
                nodes {
                    id
                    titulo
                    resumen
                    autor {
                        nombre
                    }
                    categoria{
                        nombre
                    }
                    imagen{
                        sharp: childImageSharp{
                            fluid(maxWidth: 600, maxHeight: 400){
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
            }
        }
    `);
    //console.log(datos);
    return datos.allStrapiArticulos.nodes.map(articulo => ({
        titulo: articulo.titulo,
        resumen: articulo.resumen,
        id: articulo.id,
        autor: articulo.autor,
        categoria: articulo.categoria,
        imagen: articulo.imagen
    }))
}

export default useArticulos;

