import React, {useState, useEffect} from 'react';
import { css } from "@emotion/react";

import useArticulos from '../hooks/useArticulos';
import ArticuloPreview from '../components/articuloPreview';
import listadoarticulosCSS from '../css/listadoArticulos.module.css';
import useFiltro from '../hooks/useFiltro';

const ListadoArticulos = () => {

    const resultado = useArticulos();
    const [articulos] = useState(resultado);
    const [filtrados, setFiltrados] = useState([]);

    //console.log(resultado);
    // filtrado de articulos
    const { categoria, FiltroUI } = useFiltro();

    useEffect(() => {
        if (categoria) {
            const filtro = articulos.filter( articulo => articulo.categoria.nombre === categoria);
            setFiltrados(filtro);
        } else {
            setFiltrados(articulos);
        }
        
    }, [categoria, articulos]);

    return ( 
        <> 
        <h2
            css={css`margin-top: 5rem;`}
        >Artículos Publicados</h2>
        {FiltroUI()}
        <ul className={listadoarticulosCSS.articulos}>
            {filtrados.map( articulo => (
                <ArticuloPreview 
                    key={articulo.id}
                    articulo={articulo}
                />
            ))}
        </ul>
        </>
    );
}
 
export default ListadoArticulos;