import React, {useState, useEffect} from 'react';
import { css } from "@emotion/react";

import useTarjetas from '../hooks/useTarjetas';
import TarjetaPreview from '../components/tarjetaPreview';
import listadoarticulosCSS from '../css/listadoArticulos.module.css';
import useFiltroTema from '../hooks/useFiltroTema';


const ListadoTarjetas = () => {

    const resultado = useTarjetas();
    const [tarjetas] = useState(resultado);
    const [filtrados, setFiltrados] = useState([]);

    // filtrado de tarjetas
    const { tema, FiltroUI } = useFiltroTema();
    console.log(tema);
    useEffect(() => {
        if (tema) {
            const filtro = tarjetas.filter( tarjeta => tarjeta.tema.nombre === tema);
            setFiltrados(filtro);
        } else {
            setFiltrados(tarjetas)
        }        
    }, [tema, tarjetas]);

    console.log(tarjetas);

    return ( 
        <> 
        <h2
            css={css`margin-top: 5rem;`}
        >Mis Mensajes</h2>
        {FiltroUI()}
        <ul className={listadoarticulosCSS.articulos}>
            {filtrados.map( tarjeta => (
                <TarjetaPreview 
                    key={tarjeta.id}
                    tarjeta={tarjeta}
                />
            ))}
        </ul>
        </>
    );
}
 
export default ListadoTarjetas;