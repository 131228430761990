import React from 'react';
import styled from '@emotion/styled';
import Image from 'gatsby-image';
import { graphql } from "gatsby";
import ReactMarkdown from 'react-markdown';

import SEO from "../components/seo";
import Layout from './layout';
import ListadoArticulos from './listadoArticulos';
import ListadoTarjetas from './listadoTarjetas';

const ContenidoPagina = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    width: 95%;
    text-align: justify;
    

    @media (min-width: 768px){
        display: grid;
        grid-template-columns: 2fr 1fr;
        column-gap: 5rem;
    }
`;
const LaImagen = styled(Image)`
    height: 700px;
    margin-top: 2.5rem;
`;

export const query = graphql`
    query($id: String!){
        allStrapiPaginas(filter: {id: {eq: $id}}){
            nodes{
                nombre
                contenido
                imagen{
                    sharp: childImageSharp{
                        fluid(maxWidth: 1200){
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }

    }
`;

const Paginas = ({data: {allStrapiPaginas: {nodes}}}) => {

    const { nombre, contenido, imagen } = nodes[0];

    return (  
        <Layout>
            <SEO title={nombre} />
            <main className="contenedor">
                <h1>{nombre}</h1>
                <ContenidoPagina>
                    <LaImagen 
                        fluid={imagen.sharp.fluid}
                        className="imgPg"
                    />
                    <ReactMarkdown
                        className="estiloCont"
                    >{contenido}</ReactMarkdown>      
                </ContenidoPagina>
            </main>
            {nombre === "Mis Publicaciones" && <ListadoArticulos /> }
            {nombre === "Mis Mensajes" && <ListadoTarjetas /> }
        </Layout>
    );
}
 
export default Paginas;