import { graphql, useStaticQuery } from "gatsby";

const useTarjetas = () => {
    const datos = useStaticQuery(graphql`
        query{
            
            allStrapiTarjetas {
                    
                nodes {
                    id
                    Codigo
                    Descripcion
                    tema {
                        id
                        nombre
                    }
                    imagen {
                        
                        sharp: childImageSharp {
                            fluid(maxWidth: 300, maxHeight: 300){...GatsbyImageSharpFluid_withWebp}
                        }
                    }
                }
                
            }
                
        }
            
    `);
    //console.log(datos);
    return datos.allStrapiTarjetas.nodes.map(tarjeta => ({
        codigo: tarjeta.Codigo,
        descripcion: tarjeta.Descripcion,
        id: tarjeta.id,
        imagen: tarjeta.imagen,
        tema: tarjeta.tema
    }))
}

export default useTarjetas;