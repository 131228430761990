import React from 'react';
import styled from '@emotion/styled';
import Image from 'gatsby-image';
import { Link } from "gatsby";
import urlSlug from 'url-slug';

const Card = styled.div`
    border: 1px solid #e1e1e1;
    img{
        max-width: 100%
    }
`;
const Contenido = styled.div`
    padding: 2rem;
    h3{
        font-family: 'Lato', sans-serif;
        margin: 0 0 2rem 0;
        font-size: 2rem;
    }
    .resum{
        font-size: 1.4rem;
        font-family: 'Roboto', sans-serif;
        color: #272727;
    }
`;
const Boton = styled(Link)`
    margin-top: 2rem;
    padding: 1rem;
    background-color: #222831;
    width: 100%;
    color: #ffffff;
    display: block;
    text-align: center;
    text-decoration: none;
    font-weight: 700;
    text-transform: uppercase;

    :hover{
        background-color: #30475e;
    }
`;
const ArticuloPreview = ({articulo}) => {
    const { titulo, resumen, imagen } = articulo
    return (  
        <Card>
            <Image 
                fluid={imagen.sharp.fluid}
            />
            <Contenido>
                <h3>{titulo}</h3>
                <p className="resum">{resumen}</p>
            </Contenido>
            <Boton to={`/${urlSlug(titulo)}`}>
                Ver la Publicación
            </Boton>
        </Card>
        
    );
}
 
export default ArticuloPreview;